var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var COMPILED = !0,
    goog = goog || {};
goog.global = exports;

goog.exportPath_ = function (a, b, c) {
  a = a.split(".");
  c = c || goog.global;
  !(a[0] in c) && c.execScript && c.execScript("var " + a[0]);

  for (var d; a.length && (d = a.shift());) !a.length && void 0 !== b ? c[d] = b : c = c[d] ? c[d] : c[d] = {};
};

goog.define = function (a, b) {
  var c = b;
  COMPILED || goog.global.CLOSURE_DEFINES && Object.prototype.hasOwnProperty.call(goog.global.CLOSURE_DEFINES, a) && (c = goog.global.CLOSURE_DEFINES[a]);
  goog.exportPath_(a, c);
};

goog.DEBUG = !0;
goog.define("goog.LOCALE", "en");
goog.define("goog.TRUSTED_SITE", !0);

goog.provide = function (a) {
  if (!COMPILED) {
    if (goog.isProvided_(a)) throw Error("Namespace \"" + a + "\" already declared.");
    delete goog.implicitNamespaces_[a];

    for (var b = a; (b = b.substring(0, b.lastIndexOf("."))) && !goog.getObjectByName(b);) goog.implicitNamespaces_[b] = !0;
  }

  goog.exportPath_(a);
};

goog.setTestOnly = function (a) {
  if (COMPILED && !goog.DEBUG) throw a = a || "", Error("Importing test-only code into non-debug environment" + a ? ": " + a : ".");
};

goog.forwardDeclare = function () {};

COMPILED || (goog.isProvided_ = function (a) {
  return !goog.implicitNamespaces_[a] && goog.isDefAndNotNull(goog.getObjectByName(a));
}, goog.implicitNamespaces_ = {});

goog.getObjectByName = function (a, b) {
  for (var c = a.split("."), d = b || goog.global, e; e = c.shift();) if (goog.isDefAndNotNull(d[e])) d = d[e];else return null;

  return d;
};

goog.globalize = function (a, b) {
  var c = b || goog.global,
      d;

  for (d in a) c[d] = a[d];
};

goog.addDependency = function (a, b, c) {
  if (goog.DEPENDENCIES_ENABLED) {
    for (var d, a = a.replace(/\\/g, "/"), e = goog.dependencies_, f = 0; d = b[f]; f++) {
      e.nameToPath[d] = a;
      a in e.pathToNames || (e.pathToNames[a] = {});
      e.pathToNames[a][d] = true;
    }

    for (d = 0; b = c[d]; d++) {
      a in e.requires || (e.requires[a] = {});
      e.requires[a][b] = true;
    }
  }
};

goog.define("goog.ENABLE_DEBUG_LOADER", !0);

goog.require = function (a) {
  if (!COMPILED && !goog.isProvided_(a)) {
    if (goog.ENABLE_DEBUG_LOADER) {
      var b = goog.getPathFromDeps_(a);

      if (b) {
        goog.included_[b] = true;
        goog.writeScripts_();
        return;
      }
    }

    a = "goog.require could not find: " + a;
    goog.global.console && goog.global.console.error(a);
    throw Error(a);
  }
};

goog.basePath = "";

goog.nullFunction = function () {};

goog.identityFunction = function (a) {
  return a;
};

goog.abstractMethod = function () {
  throw Error("unimplemented abstract method");
};

goog.addSingletonGetter = function (a) {
  a.getInstance = function () {
    if (a.instance_) return a.instance_;
    goog.DEBUG && (goog.instantiatedSingletons_[goog.instantiatedSingletons_.length] = a);
    return a.instance_ = new a();
  };
};

goog.instantiatedSingletons_ = [];
goog.DEPENDENCIES_ENABLED = !COMPILED && goog.ENABLE_DEBUG_LOADER;
goog.DEPENDENCIES_ENABLED && (goog.included_ = {}, goog.dependencies_ = {
  pathToNames: {},
  nameToPath: {},
  requires: {},
  visited: {},
  written: {}
}, goog.inHtmlDocument_ = function () {
  var a = goog.global.document;
  return typeof a != "undefined" && "write" in a;
}, goog.findBasePath_ = function () {
  if (goog.global.CLOSURE_BASE_PATH) goog.basePath = goog.global.CLOSURE_BASE_PATH;else if (goog.inHtmlDocument_()) for (var a = goog.global.document.getElementsByTagName("script"), b = a.length - 1; b >= 0; --b) {
    var c = a[b].src,
        d = c.lastIndexOf("?"),
        d = d == -1 ? c.length : d;

    if (c.substr(d - 7, 7) == "base.js") {
      goog.basePath = c.substr(0, d - 7);
      break;
    }
  }
}, goog.importScript_ = function (a) {
  var b = goog.global.CLOSURE_IMPORT_SCRIPT || goog.writeScriptTag_;
  !goog.dependencies_.written[a] && b(a) && (goog.dependencies_.written[a] = true);
}, goog.writeScriptTag_ = function (a) {
  if (goog.inHtmlDocument_()) {
    var b = goog.global.document;

    if (b.readyState == "complete") {
      if (/\bdeps.js$/.test(a)) return false;
      throw Error("Cannot write \"" + a + "\" after document load");
    }

    b.write("<script type=\"text/javascript\" src=\"" + a + "\"></script>");
    return true;
  }

  return false;
}, goog.writeScripts_ = function () {
  function a(e) {
    if (!(e in d.written)) {
      if (!(e in d.visited)) {
        d.visited[e] = true;
        if (e in d.requires) for (var g in d.requires[e]) if (!goog.isProvided_(g)) if (g in d.nameToPath) a(d.nameToPath[g]);else throw Error("Undefined nameToPath for " + g);
      }

      if (!(e in c)) {
        c[e] = true;
        b.push(e);
      }
    }
  }

  var b = [],
      c = {},
      d = goog.dependencies_,
      e;

  for (e in goog.included_) d.written[e] || a(e);

  for (e = 0; e < b.length; e++) if (b[e]) goog.importScript_(goog.basePath + b[e]);else throw Error("Undefined script input");
}, goog.getPathFromDeps_ = function (a) {
  return a in goog.dependencies_.nameToPath ? goog.dependencies_.nameToPath[a] : null;
}, goog.findBasePath_(), goog.global.CLOSURE_NO_DEPS || goog.importScript_(goog.basePath + "deps.js"));

goog.typeOf = function (a) {
  var b = typeof a;
  if (b == "object") {
    if (a) {
      if (a instanceof Array) return "array";
      if (a instanceof Object) return b;
      var c = Object.prototype.toString.call(a);
      if (c == "[object Window]") return "object";
      if (c == "[object Array]" || typeof a.length == "number" && typeof a.splice != "undefined" && typeof a.propertyIsEnumerable != "undefined" && !a.propertyIsEnumerable("splice")) return "array";
      if (c == "[object Function]" || typeof a.call != "undefined" && typeof a.propertyIsEnumerable != "undefined" && !a.propertyIsEnumerable("call")) return "function";
    } else return "null";
  } else if (b == "function" && typeof a.call == "undefined") return "object";
  return b;
};

goog.isDef = function (a) {
  return a !== void 0;
};

goog.isNull = function (a) {
  return a === null;
};

goog.isDefAndNotNull = function (a) {
  return a != null;
};

goog.isArray = function (a) {
  return goog.typeOf(a) == "array";
};

goog.isArrayLike = function (a) {
  var b = goog.typeOf(a);
  return b == "array" || b == "object" && typeof a.length == "number";
};

goog.isDateLike = function (a) {
  return goog.isObject(a) && typeof a.getFullYear == "function";
};

goog.isString = function (a) {
  return typeof a == "string";
};

goog.isBoolean = function (a) {
  return typeof a == "boolean";
};

goog.isNumber = function (a) {
  return typeof a == "number";
};

goog.isFunction = function (a) {
  return goog.typeOf(a) == "function";
};

goog.isObject = function (a) {
  var b = typeof a;
  return b == "object" && a != null || b == "function";
};

goog.getUid = function (a) {
  return a[goog.UID_PROPERTY_] || (a[goog.UID_PROPERTY_] = ++goog.uidCounter_);
};

goog.hasUid = function (a) {
  return !!a[goog.UID_PROPERTY_];
};

goog.removeUid = function (a) {
  "removeAttribute" in a && a.removeAttribute(goog.UID_PROPERTY_);

  try {
    delete a[goog.UID_PROPERTY_];
  } catch (b) {}
};

goog.UID_PROPERTY_ = "closure_uid_" + (1000000000 * Math.random() >>> 0);
goog.uidCounter_ = 0;
goog.getHashCode = goog.getUid;
goog.removeHashCode = goog.removeUid;

goog.cloneObject = function (a) {
  var b = goog.typeOf(a);

  if (b == "object" || b == "array") {
    if (a.clone) return a.clone();
    var b = b == "array" ? [] : {},
        c;

    for (c in a) b[c] = goog.cloneObject(a[c]);

    return b;
  }

  return a;
};

goog.bindNative_ = function (a, b, c) {
  return a.call.apply(a.bind, arguments);
};

goog.bindJs_ = function (a, b, c) {
  if (!a) throw Error();

  if (arguments.length > 2) {
    var d = Array.prototype.slice.call(arguments, 2);
    return function () {
      var c = Array.prototype.slice.call(arguments);
      Array.prototype.unshift.apply(c, d);
      return a.apply(b, c);
    };
  }

  return function () {
    return a.apply(b, arguments);
  };
};

goog.bind = function (a, b, c) {
  goog.bind = Function.prototype.bind && Function.prototype.bind.toString().indexOf("native code") != -1 ? goog.bindNative_ : goog.bindJs_;
  return goog.bind.apply(null, arguments);
};

goog.partial = function (a, b) {
  var c = Array.prototype.slice.call(arguments, 1);
  return function () {
    var b = c.slice();
    b.push.apply(b, arguments);
    return a.apply(this || _global, b);
  };
};

goog.mixin = function (a, b) {
  for (var c in b) a[c] = b[c];
};

goog.now = goog.TRUSTED_SITE && Date.now || function () {
  return +new Date();
};

goog.globalEval = function (a) {
  if (goog.global.execScript) goog.global.execScript(a, "JavaScript");else if (goog.global.eval) {
    if (goog.evalWorksForGlobals_ == null) {
      goog.global.eval("var _et_ = 1;");

      if (typeof goog.global._et_ != "undefined") {
        delete goog.global._et_;
        goog.evalWorksForGlobals_ = true;
      } else goog.evalWorksForGlobals_ = false;
    }

    if (goog.evalWorksForGlobals_) goog.global.eval(a);else {
      var b = goog.global.document,
          c = b.createElement("script");
      c.type = "text/javascript";
      c.defer = false;
      c.appendChild(b.createTextNode(a));
      b.body.appendChild(c);
      b.body.removeChild(c);
    }
  } else throw Error("goog.globalEval not available");
};

goog.evalWorksForGlobals_ = null;

goog.getCssName = function (a, b) {
  var c = function (a) {
    return goog.cssNameMapping_[a] || a;
  },
      d = function (a) {
    for (var a = a.split("-"), b = [], d = 0; d < a.length; d++) b.push(c(a[d]));

    return b.join("-");
  },
      d = goog.cssNameMapping_ ? goog.cssNameMappingStyle_ == "BY_WHOLE" ? c : d : function (a) {
    return a;
  };

  return b ? a + "-" + d(b) : d(a);
};

goog.setCssNameMapping = function (a, b) {
  goog.cssNameMapping_ = a;
  goog.cssNameMappingStyle_ = b;
};

!COMPILED && goog.global.CLOSURE_CSS_NAME_MAPPING && (goog.cssNameMapping_ = goog.global.CLOSURE_CSS_NAME_MAPPING);

goog.getMsg = function (a, b) {
  var c = b || {},
      d;

  for (d in c) var e = ("" + c[d]).replace(/\$/g, "$$$$"), a = a.replace(RegExp("\\{\\$" + d + "\\}", "gi"), e);

  return a;
};

goog.getMsgWithFallback = function (a) {
  return a;
};

goog.exportSymbol = function (a, b, c) {
  goog.exportPath_(a, b, c);
};

goog.exportProperty = function (a, b, c) {
  a[b] = c;
};

goog.inherits = function (a, b) {
  function c() {}

  c.prototype = b.prototype;
  a.superClass_ = b.prototype;
  a.prototype = new c();
  a.prototype.constructor = a;

  a.base = function (a, c, f) {
    var g = Array.prototype.slice.call(arguments, 2);
    return b.prototype[c].apply(a, g);
  };
};

goog.base = function (a, b, c) {
  var d = arguments.callee.caller;
  if (goog.DEBUG && !d) throw Error("arguments.caller not defined.  goog.base() expects not to be running in strict mode. See http://www.ecma-international.org/ecma-262/5.1/#sec-C");
  if (d.superClass_) return d.superClass_.constructor.apply(a, Array.prototype.slice.call(arguments, 1));

  for (var e = Array.prototype.slice.call(arguments, 2), f = false, g = a.constructor; g; g = g.superClass_ && g.superClass_.constructor) if (g.prototype[b] === d) f = true;else if (f) return g.prototype[b].apply(a, e);

  if (a[b] === d) return a.constructor.prototype[b].apply(a, e);
  throw Error("goog.base called from a method of one name to a method of a different name");
};

goog.scope = function (a) {
  a.call(goog.global);
};

var fb = {
  tokengenerator: {}
};
fb.tokengenerator.constants = {};
var NODE_CLIENT = !0;
goog.json = {};
goog.define("goog.json.USE_NATIVE_JSON", !1);

goog.json.isValid_ = function (a) {
  return /^\s*$/.test(a) ? !1 : /^[\],:{}\s\u2028\u2029]*$/.test(a.replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r\u2028\u2029\x00-\x08\x0a-\x1f]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:[\s\u2028\u2029]*\[)+/g, ""));
};

goog.json.parse = goog.json.USE_NATIVE_JSON ? goog.global.JSON.parse : function (a) {
  a = String(a);
  if (goog.json.isValid_(a)) try {
    return eval("(" + a + ")");
  } catch (b) {}
  throw Error("Invalid JSON string: " + a);
};
goog.json.unsafeParse = goog.json.USE_NATIVE_JSON ? goog.global.JSON.parse : function (a) {
  return eval("(" + a + ")");
};
goog.json.serialize = goog.json.USE_NATIVE_JSON ? goog.global.JSON.stringify : function (a, b) {
  return new goog.json.Serializer(b).serialize(a);
};

goog.json.Serializer = function (a) {
  (this || _global).replacer_ = a;
};

goog.json.Serializer.prototype.serialize = function (a) {
  var b = [];
  this.serialize_(a, b);
  return b.join("");
};

goog.json.Serializer.prototype.serialize_ = function (a, b) {
  switch (typeof a) {
    case "string":
      this.serializeString_(a, b);
      break;

    case "number":
      this.serializeNumber_(a, b);
      break;

    case "boolean":
      b.push(a);
      break;

    case "undefined":
      b.push("null");
      break;

    case "object":
      if (null == a) {
        b.push("null");
        break;
      }

      if (goog.isArray(a)) {
        this.serializeArray(a, b);
        break;
      }

      this.serializeObject_(a, b);
      break;

    case "function":
      break;

    default:
      throw Error("Unknown type: " + typeof a);
  }
};

goog.json.Serializer.charToJsonCharCache_ = {
  "\"": "\\\"",
  "\\": "\\\\",
  "/": "\\/",
  "\b": "\\b",
  "\f": "\\f",
  "\n": "\\n",
  "\r": "\\r",
  "\t": "\\t",
  "\x0B": "\\u000b"
};
goog.json.Serializer.charsToReplace_ = /\uffff/.test("\uFFFF") ? /[\\\"\x00-\x1f\x7f-\uffff]/g : /[\\\"\x00-\x1f\x7f-\xff]/g;

goog.json.Serializer.prototype.serializeString_ = function (a, b) {
  b.push("\"", a.replace(goog.json.Serializer.charsToReplace_, function (a) {
    if (a in goog.json.Serializer.charToJsonCharCache_) return goog.json.Serializer.charToJsonCharCache_[a];
    var b = a.charCodeAt(0),
        e = "\\u";
    16 > b ? e += "000" : 256 > b ? e += "00" : 4096 > b && (e += "0");
    return goog.json.Serializer.charToJsonCharCache_[a] = e + b.toString(16);
  }), "\"");
};

goog.json.Serializer.prototype.serializeNumber_ = function (a, b) {
  b.push(isFinite(a) && !isNaN(a) ? a : "null");
};

goog.json.Serializer.prototype.serializeArray = function (a, b) {
  var c = a.length;
  b.push("[");

  for (var d = "", e = 0; e < c; e++) b.push(d), d = a[e], this.serialize_((this || _global).replacer_ ? (this || _global).replacer_.call(a, String(e), d) : d, b), d = ",";

  b.push("]");
};

goog.json.Serializer.prototype.serializeObject_ = function (a, b) {
  b.push("{");
  var c = "",
      d;

  for (d in a) if (Object.prototype.hasOwnProperty.call(a, d)) {
    var e = a[d];
    "function" != typeof e && (b.push(c), this.serializeString_(d, b), b.push(":"), this.serialize_((this || _global).replacer_ ? (this || _global).replacer_.call(a, d, e) : e, b), c = ",");
  }

  b.push("}");
};

fb.tokengenerator.json = {};

fb.tokengenerator.json.parse = function (a) {
  return "undefined" !== typeof JSON && goog.isDef(JSON.parse) ? JSON.parse(a) : goog.json.parse(a);
};

fb.tokengenerator.json.stringify = function (a) {
  return "undefined" !== typeof JSON && goog.isDef(JSON.stringify) ? JSON.stringify(a) : goog.json.serialize(a);
};

fb.tokengenerator.utf8 = {};

fb.tokengenerator.utf8.stringToByteArray = function (a) {
  for (var b = [], c = 0, d = 0; d < a.length; d++) {
    var e = a.charCodeAt(d);

    if (55296 <= e && 56319 >= e) {
      e -= 55296;
      d++;
      fb.core.util.assert(d < a.length, "Surrogate pair missing trail surrogate.");
      var f = a.charCodeAt(d) - 56320,
          e = 65536 + (e << 10) + f;
    }

    128 > e ? b[c++] = e : (2048 > e ? b[c++] = e >> 6 | 192 : (65536 > e ? b[c++] = e >> 12 | 224 : (b[c++] = e >> 18 | 240, b[c++] = e >> 12 & 63 | 128), b[c++] = e >> 6 & 63 | 128), b[c++] = e & 63 | 128);
  }

  return b;
};

fb.tokengenerator.utf8.stringLength = function (a) {
  for (var b = 0, c = 0; c < a.length; c++) {
    var d = a.charCodeAt(c);
    128 > d ? b++ : 2048 > d ? b += 2 : 55296 <= d && 56319 >= d ? (b += 4, c++) : b += 3;
  }

  return b;
};

var CryptoJS = function (a, b) {
  var c = {},
      d = c.lib = {},
      e = function () {},
      f = d.Base = {
    extend: function (a) {
      e.prototype = this || _global;
      var b = new e();
      a && b.mixIn(a);
      b.$super = this || _global;
      return b;
    },
    create: function () {
      var a = this.extend();
      a.init.apply(a, arguments);
      return a;
    },
    init: function () {},
    mixIn: function (a) {
      for (var b in a) a.hasOwnProperty(b) && ((this || _global)[b] = a[b]);

      a.hasOwnProperty("toString") && ((this || _global).toString = a.toString);
    },
    clone: function () {
      return (this || _global).$super.extend(this || _global);
    }
  },
      g = d.WordArray = f.extend({
    init: function (a, c) {
      a = (this || _global).words = a || [];
      (this || _global).sigBytes = c != b ? c : 4 * a.length;
    },
    toString: function (a) {
      return (a || i).stringify(this || _global);
    },
    concat: function (a) {
      var b = (this || _global).words,
          c = a.words,
          d = (this || _global).sigBytes,
          a = a.sigBytes;
      this.clamp();
      if (d % 4) for (var e = 0; e < a; e++) b[d + e >>> 2] |= (c[e >>> 2] >>> 24 - 8 * (e % 4) & 255) << 24 - 8 * ((d + e) % 4);else if (65535 < c.length) for (e = 0; e < a; e += 4) b[d + e >>> 2] = c[e >>> 2];else b.push.apply(b, c);
      (this || _global).sigBytes += a;
      return this || _global;
    },
    clamp: function () {
      var b = (this || _global).words,
          c = (this || _global).sigBytes;
      b[c >>> 2] &= 4294967295 << 32 - 8 * (c % 4);
      b.length = a.ceil(c / 4);
    },
    clone: function () {
      var a = f.clone.call(this || _global);
      a.words = (this || _global).words.slice(0);
      return a;
    },
    random: function (b) {
      for (var c = [], d = 0; d < b; d += 4) c.push(4294967296 * a.random() | 0);

      return g.create(c, b);
    }
  }),
      h = c.enc = {},
      i = h.Hex = {
    stringify: function (a) {
      for (var b = a.words, a = a.sigBytes, c = [], d = 0; d < a; d++) {
        var e = b[d >>> 2] >>> 24 - 8 * (d % 4) & 255;
        c.push((e >>> 4).toString(16));
        c.push((e & 15).toString(16));
      }

      return c.join("");
    },
    parse: function (a) {
      for (var b = a.length, c = [], d = 0; d < b; d += 2) c[d >>> 3] |= parseInt(a.substr(d, 2), 16) << 24 - 4 * (d % 8);

      return g.create(c, b / 2);
    }
  },
      j = h.Latin1 = {
    stringify: function (a) {
      for (var b = a.words, a = a.sigBytes, c = [], d = 0; d < a; d++) c.push(String.fromCharCode(b[d >>> 2] >>> 24 - 8 * (d % 4) & 255));

      return c.join("");
    },
    parse: function (a) {
      for (var b = a.length, c = [], d = 0; d < b; d++) c[d >>> 2] |= (a.charCodeAt(d) & 255) << 24 - 8 * (d % 4);

      return g.create(c, b);
    }
  },
      k = h.Utf8 = {
    stringify: function (a) {
      try {
        return decodeURIComponent(escape(j.stringify(a)));
      } catch (b) {
        throw Error("Malformed UTF-8 data");
      }
    },
    parse: function (a) {
      return j.parse(unescape(encodeURIComponent(a)));
    }
  },
      m = d.BufferedBlockAlgorithm = f.extend({
    reset: function () {
      (this || _global)._data = g.create();
      (this || _global)._nDataBytes = 0;
    },
    _append: function (a) {
      "string" == typeof a && (a = k.parse(a));

      (this || _global)._data.concat(a);

      (this || _global)._nDataBytes += a.sigBytes;
    },
    _process: function (b) {
      var c = (this || _global)._data,
          d = c.words,
          e = c.sigBytes,
          f = (this || _global).blockSize,
          h = e / (4 * f),
          h = b ? a.ceil(h) : a.max((h | 0) - (this || _global)._minBufferSize, 0),
          b = h * f,
          e = a.min(4 * b, e);

      if (b) {
        for (var i = 0; i < b; i += f) this._doProcessBlock(d, i);

        i = d.splice(0, b);
        c.sigBytes -= e;
      }

      return g.create(i, e);
    },
    clone: function () {
      var a = f.clone.call(this || _global);
      a._data = (this || _global)._data.clone();
      return a;
    },
    _minBufferSize: 0
  });

  d.Hasher = m.extend({
    init: function () {
      this.reset();
    },
    reset: function () {
      m.reset.call(this || _global);

      this._doReset();
    },
    update: function (a) {
      this._append(a);

      this._process();

      return this || _global;
    },
    finalize: function (a) {
      a && this._append(a);

      this._doFinalize();

      return (this || _global)._hash;
    },
    clone: function () {
      var a = m.clone.call(this || _global);
      a._hash = (this || _global)._hash.clone();
      return a;
    },
    blockSize: 16,
    _createHelper: function (a) {
      return function (b, c) {
        return a.create(c).finalize(b);
      };
    },
    _createHmacHelper: function (a) {
      return function (b, c) {
        return l.HMAC.create(a, c).finalize(b);
      };
    }
  });
  var l = c.algo = {};
  return c;
}(Math);

(function (a) {
  for (var b = CryptoJS, c = b.lib, d = c.WordArray, c = c.Hasher, e = b.algo, f = [], g = [], h = function (a) {
    return 4294967296 * (a - (a | 0)) | 0;
  }, i = 2, j = 0; 64 > j;) {
    var k;

    a: {
      k = i;

      for (var m = a.sqrt(k), l = 2; l <= m; l++) if (!(k % l)) {
        k = !1;
        break a;
      }

      k = !0;
    }

    k && (8 > j && (f[j] = h(a.pow(i, 0.5))), g[j] = h(a.pow(i, 1 / 3)), j++);
    i++;
  }

  var o = [],
      e = e.SHA256 = c.extend({
    _doReset: function () {
      (this || _global)._hash = d.create(f.slice(0));
    },
    _doProcessBlock: function (a, b) {
      for (var c = (this || _global)._hash.words, d = c[0], e = c[1], f = c[2], h = c[3], i = c[4], j = c[5], k = c[6], m = c[7], n = 0; 64 > n; n++) {
        if (16 > n) o[n] = a[b + n] | 0;else {
          var l = o[n - 15],
              p = o[n - 2];
          o[n] = ((l << 25 | l >>> 7) ^ (l << 14 | l >>> 18) ^ l >>> 3) + o[n - 7] + ((p << 15 | p >>> 17) ^ (p << 13 | p >>> 19) ^ p >>> 10) + o[n - 16];
        }
        l = m + ((i << 26 | i >>> 6) ^ (i << 21 | i >>> 11) ^ (i << 7 | i >>> 25)) + (i & j ^ ~i & k) + g[n] + o[n];
        p = ((d << 30 | d >>> 2) ^ (d << 19 | d >>> 13) ^ (d << 10 | d >>> 22)) + (d & e ^ d & f ^ e & f);
        m = k;
        k = j;
        j = i;
        i = h + l | 0;
        h = f;
        f = e;
        e = d;
        d = l + p | 0;
      }

      c[0] = c[0] + d | 0;
      c[1] = c[1] + e | 0;
      c[2] = c[2] + f | 0;
      c[3] = c[3] + h | 0;
      c[4] = c[4] + i | 0;
      c[5] = c[5] + j | 0;
      c[6] = c[6] + k | 0;
      c[7] = c[7] + m | 0;
    },
    _doFinalize: function () {
      var a = (this || _global)._data,
          b = a.words,
          c = 8 * (this || _global)._nDataBytes,
          d = 8 * a.sigBytes;
      b[d >>> 5] |= 128 << 24 - d % 32;
      b[(d + 64 >>> 9 << 4) + 15] = c;
      a.sigBytes = 4 * b.length;

      this._process();
    }
  });
  b.SHA256 = c._createHelper(e);
  b.HmacSHA256 = c._createHmacHelper(e);
})(Math);

(function () {
  var a = CryptoJS,
      b = a.enc.Utf8;
  a.algo.HMAC = a.lib.Base.extend({
    init: function (a, d) {
      a = (this || _global)._hasher = a.create();
      "string" == typeof d && (d = b.parse(d));
      var e = a.blockSize,
          f = 4 * e;
      d.sigBytes > f && (d = a.finalize(d));

      for (var g = (this || _global)._oKey = d.clone(), h = (this || _global)._iKey = d.clone(), i = g.words, j = h.words, k = 0; k < e; k++) i[k] ^= 1549556828, j[k] ^= 909522486;

      g.sigBytes = h.sigBytes = f;
      this.reset();
    },
    reset: function () {
      var a = (this || _global)._hasher;
      a.reset();
      a.update((this || _global)._iKey);
    },
    update: function (a) {
      (this || _global)._hasher.update(a);

      return this || _global;
    },
    finalize: function (a) {
      var b = (this || _global)._hasher,
          a = b.finalize(a);
      b.reset();
      return b.finalize((this || _global)._oKey.clone().concat(a));
    }
  });
})();

fb.tokengenerator.validation = {};

fb.tokengenerator.validation.validateArgCount = function (a, b, c, d) {
  var e;
  d < b ? e = "at least " + b : d > c && (e = 0 === c ? "none" : "no more than " + c);
  if (e) throw Error(a + " failed: Was called with " + d + (1 === d ? " argument." : " arguments.") + " Expects " + e + ".");
};

fb.tokengenerator.validation.errorPrefix_ = function (a, b, c) {
  var d = "";

  switch (b) {
    case 1:
      d = c ? "first" : "First";
      break;

    case 2:
      d = c ? "second" : "Second";
      break;

    case 3:
      d = c ? "third" : "Third";
      break;

    case 4:
      d = c ? "fourth" : "Fourth";
      break;

    default:
      fb.core.util.validation.assert(!1, "errorPrefix_ called with argumentNumber > 4.  Need to update it?");
  }

  return a + " failed: " + (d + " argument ");
};

fb.tokengenerator.validation.validateSecret = function (a, b, c) {
  if (!goog.isString(c)) throw Error(fb.tokengenerator.validation.errorPrefix_(a, b, !1) + "must be a valid firebase namespace secret.");
};

fb.tokengenerator.validation.validateCredentialData = function (a, b, c, d) {
  var e = "object" === typeof c;

  if (null === c || !e) {
    if (!e && !d) throw Error(fb.tokengenerator.validation.errorPrefix_(a, b, !1) + "must be a dictionary of token data.");
  } else if (null === c.uid || "string" !== typeof c.uid) {
    if (!d || "undefined" !== typeof c.uid) throw Error(fb.tokengenerator.validation.errorPrefix_(a, b, !1) + "must contain a \"uid\" key that must be a string.");
  } else if (256 < c.uid.length) throw Error(fb.tokengenerator.validation.errorPrefix_(a, b, !1) + "must contain a \"uid\" key that must not be longer than 256 bytes.");
};

fb.tokengenerator.validation.validateCredentialOptions = function (a, b, c) {
  if (goog.isDef(c) && (null === c || "object" !== typeof c)) throw Error(fb.tokengenerator.validation.errorPrefix_(a, b, !0) + "must be a dictionary of token options.");
};

fb.tokengenerator.validation.validateOption = function (a, b, c, d, e) {
  if (typeof c !== d || "number" === d && isNaN(c)) throw Error(a + " option \"" + b + "\" must be " + e + ", instead got " + c);
};

fb.tokengenerator.validation.validateGeneratedToken = function (a) {
  if (1024 < a.length) throw Error("Generated token must be less than 1024 bytes long");
};

goog.dom = {};
goog.dom.NodeType = {
  ELEMENT: 1,
  ATTRIBUTE: 2,
  TEXT: 3,
  CDATA_SECTION: 4,
  ENTITY_REFERENCE: 5,
  ENTITY: 6,
  PROCESSING_INSTRUCTION: 7,
  COMMENT: 8,
  DOCUMENT: 9,
  DOCUMENT_TYPE: 10,
  DOCUMENT_FRAGMENT: 11,
  NOTATION: 12
};
goog.debug = {};

goog.debug.Error = function (a) {
  if (Error.captureStackTrace) Error.captureStackTrace(this || _global, goog.debug.Error);else {
    var b = Error().stack;
    b && ((this || _global).stack = b);
  }
  a && ((this || _global).message = String(a));
};

goog.inherits(goog.debug.Error, Error);
goog.debug.Error.prototype.name = "CustomError";
goog.string = {};
goog.string.Unicode = {
  NBSP: "\xA0"
};

goog.string.startsWith = function (a, b) {
  return 0 == a.lastIndexOf(b, 0);
};

goog.string.endsWith = function (a, b) {
  var c = a.length - b.length;
  return 0 <= c && a.indexOf(b, c) == c;
};

goog.string.caseInsensitiveStartsWith = function (a, b) {
  return 0 == goog.string.caseInsensitiveCompare(b, a.substr(0, b.length));
};

goog.string.caseInsensitiveEndsWith = function (a, b) {
  return 0 == goog.string.caseInsensitiveCompare(b, a.substr(a.length - b.length, b.length));
};

goog.string.caseInsensitiveEquals = function (a, b) {
  return a.toLowerCase() == b.toLowerCase();
};

goog.string.subs = function (a, b) {
  for (var c = a.split("%s"), d = "", e = Array.prototype.slice.call(arguments, 1); e.length && 1 < c.length;) d += c.shift() + e.shift();

  return d + c.join("%s");
};

goog.string.collapseWhitespace = function (a) {
  return a.replace(/[\s\xa0]+/g, " ").replace(/^\s+|\s+$/g, "");
};

goog.string.isEmpty = function (a) {
  return /^[\s\xa0]*$/.test(a);
};

goog.string.isEmptySafe = function (a) {
  return goog.string.isEmpty(goog.string.makeSafe(a));
};

goog.string.isBreakingWhitespace = function (a) {
  return !/[^\t\n\r ]/.test(a);
};

goog.string.isAlpha = function (a) {
  return !/[^a-zA-Z]/.test(a);
};

goog.string.isNumeric = function (a) {
  return !/[^0-9]/.test(a);
};

goog.string.isAlphaNumeric = function (a) {
  return !/[^a-zA-Z0-9]/.test(a);
};

goog.string.isSpace = function (a) {
  return " " == a;
};

goog.string.isUnicodeChar = function (a) {
  return 1 == a.length && " " <= a && "~" >= a || "\x80" <= a && "\uFFFD" >= a;
};

goog.string.stripNewlines = function (a) {
  return a.replace(/(\r\n|\r|\n)+/g, " ");
};

goog.string.canonicalizeNewlines = function (a) {
  return a.replace(/(\r\n|\r|\n)/g, "\n");
};

goog.string.normalizeWhitespace = function (a) {
  return a.replace(/\xa0|\s/g, " ");
};

goog.string.normalizeSpaces = function (a) {
  return a.replace(/\xa0|[ \t]+/g, " ");
};

goog.string.collapseBreakingSpaces = function (a) {
  return a.replace(/[\t\r\n ]+/g, " ").replace(/^[\t\r\n ]+|[\t\r\n ]+$/g, "");
};

goog.string.trim = function (a) {
  return a.replace(/^[\s\xa0]+|[\s\xa0]+$/g, "");
};

goog.string.trimLeft = function (a) {
  return a.replace(/^[\s\xa0]+/, "");
};

goog.string.trimRight = function (a) {
  return a.replace(/[\s\xa0]+$/, "");
};

goog.string.caseInsensitiveCompare = function (a, b) {
  var c = String(a).toLowerCase(),
      d = String(b).toLowerCase();
  return c < d ? -1 : c == d ? 0 : 1;
};

goog.string.numerateCompareRegExp_ = /(\.\d+)|(\d+)|(\D+)/g;

goog.string.numerateCompare = function (a, b) {
  if (a == b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  for (var c = a.toLowerCase().match(goog.string.numerateCompareRegExp_), d = b.toLowerCase().match(goog.string.numerateCompareRegExp_), e = Math.min(c.length, d.length), f = 0; f < e; f++) {
    var g = c[f],
        h = d[f];
    if (g != h) return c = parseInt(g, 10), !isNaN(c) && (d = parseInt(h, 10), !isNaN(d) && c - d) ? c - d : g < h ? -1 : 1;
  }

  return c.length != d.length ? c.length - d.length : a < b ? -1 : 1;
};

goog.string.urlEncode = function (a) {
  return encodeURIComponent(String(a));
};

goog.string.urlDecode = function (a) {
  return decodeURIComponent(a.replace(/\+/g, " "));
};

goog.string.newLineToBr = function (a, b) {
  return a.replace(/(\r\n|\r|\n)/g, b ? "<br />" : "<br>");
};

goog.string.htmlEscape = function (a, b) {
  if (b) return a.replace(goog.string.amperRe_, "&amp;").replace(goog.string.ltRe_, "&lt;").replace(goog.string.gtRe_, "&gt;").replace(goog.string.quotRe_, "&quot;").replace(goog.string.singleQuoteRe_, "&#39;");
  if (!goog.string.allRe_.test(a)) return a;
  -1 != a.indexOf("&") && (a = a.replace(goog.string.amperRe_, "&amp;"));
  -1 != a.indexOf("<") && (a = a.replace(goog.string.ltRe_, "&lt;"));
  -1 != a.indexOf(">") && (a = a.replace(goog.string.gtRe_, "&gt;"));
  -1 != a.indexOf("\"") && (a = a.replace(goog.string.quotRe_, "&quot;"));
  -1 != a.indexOf("'") && (a = a.replace(goog.string.singleQuoteRe_, "&#39;"));
  return a;
};

goog.string.amperRe_ = /&/g;
goog.string.ltRe_ = /</g;
goog.string.gtRe_ = />/g;
goog.string.quotRe_ = /"/g;
goog.string.singleQuoteRe_ = /'/g;
goog.string.allRe_ = /[&<>"']/;

goog.string.unescapeEntities = function (a) {
  return goog.string.contains(a, "&") ? "document" in goog.global ? goog.string.unescapeEntitiesUsingDom_(a) : goog.string.unescapePureXmlEntities_(a) : a;
};

goog.string.unescapeEntitiesWithDocument = function (a, b) {
  return goog.string.contains(a, "&") ? goog.string.unescapeEntitiesUsingDom_(a, b) : a;
};

goog.string.unescapeEntitiesUsingDom_ = function (a, b) {
  var c = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": "\""
  },
      d;
  d = b ? b.createElement("div") : document.createElement("div");
  return a.replace(goog.string.HTML_ENTITY_PATTERN_, function (a, b) {
    var g = c[a];
    if (g) return g;

    if ("#" == b.charAt(0)) {
      var h = Number("0" + b.substr(1));
      isNaN(h) || (g = String.fromCharCode(h));
    }

    g || (d.innerHTML = a + " ", g = d.firstChild.nodeValue.slice(0, -1));
    return c[a] = g;
  });
};

goog.string.unescapePureXmlEntities_ = function (a) {
  return a.replace(/&([^;]+);/g, function (a, c) {
    switch (c) {
      case "amp":
        return "&";

      case "lt":
        return "<";

      case "gt":
        return ">";

      case "quot":
        return "\"";

      default:
        if ("#" == c.charAt(0)) {
          var d = Number("0" + c.substr(1));
          if (!isNaN(d)) return String.fromCharCode(d);
        }

        return a;
    }
  });
};

goog.string.HTML_ENTITY_PATTERN_ = /&([^;\s<&]+);?/g;

goog.string.whitespaceEscape = function (a, b) {
  return goog.string.newLineToBr(a.replace(/  /g, " &#160;"), b);
};

goog.string.stripQuotes = function (a, b) {
  for (var c = b.length, d = 0; d < c; d++) {
    var e = 1 == c ? b : b.charAt(d);
    if (a.charAt(0) == e && a.charAt(a.length - 1) == e) return a.substring(1, a.length - 1);
  }

  return a;
};

goog.string.truncate = function (a, b, c) {
  c && (a = goog.string.unescapeEntities(a));
  a.length > b && (a = a.substring(0, b - 3) + "...");
  c && (a = goog.string.htmlEscape(a));
  return a;
};

goog.string.truncateMiddle = function (a, b, c, d) {
  c && (a = goog.string.unescapeEntities(a));

  if (d && a.length > b) {
    d > b && (d = b);
    var e = a.length - d,
        a = a.substring(0, b - d) + "..." + a.substring(e);
  } else a.length > b && (d = Math.floor(b / 2), e = a.length - d, a = a.substring(0, d + b % 2) + "..." + a.substring(e));

  c && (a = goog.string.htmlEscape(a));
  return a;
};

goog.string.specialEscapeChars_ = {
  "\0": "\\0",
  "\b": "\\b",
  "\f": "\\f",
  "\n": "\\n",
  "\r": "\\r",
  "\t": "\\t",
  "\x0B": "\\x0B",
  "\"": "\\\"",
  "\\": "\\\\"
};
goog.string.jsEscapeCache_ = {
  "'": "\\'"
};

goog.string.quote = function (a) {
  a = String(a);
  if (a.quote) return a.quote();

  for (var b = ["\""], c = 0; c < a.length; c++) {
    var d = a.charAt(c),
        e = d.charCodeAt(0);
    b[c + 1] = goog.string.specialEscapeChars_[d] || (31 < e && 127 > e ? d : goog.string.escapeChar(d));
  }

  b.push("\"");
  return b.join("");
};

goog.string.escapeString = function (a) {
  for (var b = [], c = 0; c < a.length; c++) b[c] = goog.string.escapeChar(a.charAt(c));

  return b.join("");
};

goog.string.escapeChar = function (a) {
  if (a in goog.string.jsEscapeCache_) return goog.string.jsEscapeCache_[a];
  if (a in goog.string.specialEscapeChars_) return goog.string.jsEscapeCache_[a] = goog.string.specialEscapeChars_[a];
  var b = a,
      c = a.charCodeAt(0);
  if (31 < c && 127 > c) b = a;else {
    if (256 > c) {
      if (b = "\\x", 16 > c || 256 < c) b += "0";
    } else b = "\\u", 4096 > c && (b += "0");

    b += c.toString(16).toUpperCase();
  }
  return goog.string.jsEscapeCache_[a] = b;
};

goog.string.toMap = function (a) {
  for (var b = {}, c = 0; c < a.length; c++) b[a.charAt(c)] = !0;

  return b;
};

goog.string.contains = function (a, b) {
  return -1 != a.indexOf(b);
};

goog.string.countOf = function (a, b) {
  return a && b ? a.split(b).length - 1 : 0;
};

goog.string.removeAt = function (a, b, c) {
  var d = a;
  0 <= b && b < a.length && 0 < c && (d = a.substr(0, b) + a.substr(b + c, a.length - b - c));
  return d;
};

goog.string.remove = function (a, b) {
  var c = RegExp(goog.string.regExpEscape(b), "");
  return a.replace(c, "");
};

goog.string.removeAll = function (a, b) {
  var c = RegExp(goog.string.regExpEscape(b), "g");
  return a.replace(c, "");
};

goog.string.regExpEscape = function (a) {
  return String(a).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, "\\$1").replace(/\x08/g, "\\x08");
};

goog.string.repeat = function (a, b) {
  return Array(b + 1).join(a);
};

goog.string.padNumber = function (a, b, c) {
  a = goog.isDef(c) ? a.toFixed(c) : String(a);
  c = a.indexOf(".");
  -1 == c && (c = a.length);
  return goog.string.repeat("0", Math.max(0, b - c)) + a;
};

goog.string.makeSafe = function (a) {
  return null == a ? "" : String(a);
};

goog.string.buildString = function (a) {
  return Array.prototype.join.call(arguments, "");
};

goog.string.getRandomString = function () {
  return Math.floor(2147483648 * Math.random()).toString(36) + Math.abs(Math.floor(2147483648 * Math.random()) ^ goog.now()).toString(36);
};

goog.string.compareVersions = function (a, b) {
  for (var c = 0, d = goog.string.trim(String(a)).split("."), e = goog.string.trim(String(b)).split("."), f = Math.max(d.length, e.length), g = 0; 0 == c && g < f; g++) {
    var h = d[g] || "",
        i = e[g] || "",
        j = RegExp("(\\d*)(\\D*)", "g"),
        k = RegExp("(\\d*)(\\D*)", "g");

    do {
      var m = j.exec(h) || ["", "", ""],
          l = k.exec(i) || ["", "", ""];
      if (0 == m[0].length && 0 == l[0].length) break;
      var c = 0 == m[1].length ? 0 : parseInt(m[1], 10),
          o = 0 == l[1].length ? 0 : parseInt(l[1], 10),
          c = goog.string.compareElements_(c, o) || goog.string.compareElements_(0 == m[2].length, 0 == l[2].length) || goog.string.compareElements_(m[2], l[2]);
    } while (0 == c);
  }

  return c;
};

goog.string.compareElements_ = function (a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
};

goog.string.HASHCODE_MAX_ = 4294967296;

goog.string.hashCode = function (a) {
  for (var b = 0, c = 0; c < a.length; ++c) b = 31 * b + a.charCodeAt(c), b %= goog.string.HASHCODE_MAX_;

  return b;
};

goog.string.uniqueStringCounter_ = 2147483648 * Math.random() | 0;

goog.string.createUniqueString = function () {
  return "goog_" + goog.string.uniqueStringCounter_++;
};

goog.string.toNumber = function (a) {
  var b = Number(a);
  return 0 == b && goog.string.isEmpty(a) ? NaN : b;
};

goog.string.isLowerCamelCase = function (a) {
  return /^[a-z]+([A-Z][a-z]*)*$/.test(a);
};

goog.string.isUpperCamelCase = function (a) {
  return /^([A-Z][a-z]*)+$/.test(a);
};

goog.string.toCamelCase = function (a) {
  return String(a).replace(/\-([a-z])/g, function (a, c) {
    return c.toUpperCase();
  });
};

goog.string.toSelectorCase = function (a) {
  return String(a).replace(/([A-Z])/g, "-$1").toLowerCase();
};

goog.string.toTitleCase = function (a, b) {
  var c = goog.isString(b) ? goog.string.regExpEscape(b) : "\\s";
  return a.replace(RegExp("(^" + (c ? "|[" + c + "]+" : "") + ")([a-z])", "g"), function (a, b, c) {
    return b + c.toUpperCase();
  });
};

goog.string.parseInt = function (a) {
  isFinite(a) && (a = String(a));
  return goog.isString(a) ? /^\s*-?0x/i.test(a) ? parseInt(a, 16) : parseInt(a, 10) : NaN;
};

goog.string.splitLimit = function (a, b, c) {
  for (var a = a.split(b), d = []; 0 < c && a.length;) d.push(a.shift()), c--;

  a.length && d.push(a.join(b));
  return d;
};

goog.asserts = {};
goog.define("goog.asserts.ENABLE_ASSERTS", goog.DEBUG);

goog.asserts.AssertionError = function (a, b) {
  b.unshift(a);
  goog.debug.Error.call(this || _global, goog.string.subs.apply(null, b));
  b.shift();
  (this || _global).messagePattern = a;
};

goog.inherits(goog.asserts.AssertionError, goog.debug.Error);
goog.asserts.AssertionError.prototype.name = "AssertionError";

goog.asserts.doAssertFailure_ = function (a, b, c, d) {
  var e = "Assertion failed";
  if (c) var e = e + (": " + c),
      f = d;else a && (e += ": " + a, f = b);
  throw new goog.asserts.AssertionError("" + e, f || []);
};

goog.asserts.assert = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !a && goog.asserts.doAssertFailure_("", null, b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.fail = function (a, b) {
  if (goog.asserts.ENABLE_ASSERTS) throw new goog.asserts.AssertionError("Failure" + (a ? ": " + a : ""), Array.prototype.slice.call(arguments, 1));
};

goog.asserts.assertNumber = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isNumber(a) && goog.asserts.doAssertFailure_("Expected number but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertString = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isString(a) && goog.asserts.doAssertFailure_("Expected string but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertFunction = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isFunction(a) && goog.asserts.doAssertFailure_("Expected function but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertObject = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isObject(a) && goog.asserts.doAssertFailure_("Expected object but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertArray = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isArray(a) && goog.asserts.doAssertFailure_("Expected array but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertBoolean = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && !goog.isBoolean(a) && goog.asserts.doAssertFailure_("Expected boolean but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertElement = function (a, b, c) {
  goog.asserts.ENABLE_ASSERTS && (!goog.isObject(a) || a.nodeType != goog.dom.NodeType.ELEMENT) && goog.asserts.doAssertFailure_("Expected Element but got %s: %s.", [goog.typeOf(a), a], b, Array.prototype.slice.call(arguments, 2));
  return a;
};

goog.asserts.assertInstanceof = function (a, b, c, d) {
  goog.asserts.ENABLE_ASSERTS && !(a instanceof b) && goog.asserts.doAssertFailure_("instanceof check failed.", null, c, Array.prototype.slice.call(arguments, 3));
  return a;
};

goog.asserts.assertObjectPrototypeIsIntact = function () {
  for (var a in Object.prototype) goog.asserts.fail(a + " should not be enumerable in Object.prototype.");
};

goog.array = {};
goog.define("goog.NATIVE_ARRAY_PROTOTYPES", goog.TRUSTED_SITE);
goog.define("goog.array.ASSUME_NATIVE_FUNCTIONS", !1);

goog.array.peek = function (a) {
  return a[a.length - 1];
};

goog.array.ARRAY_PROTOTYPE_ = Array.prototype;
goog.array.indexOf = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.indexOf) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.indexOf.call(a, b, c);
} : function (a, b, c) {
  c = null == c ? 0 : 0 > c ? Math.max(0, a.length + c) : c;
  if (goog.isString(a)) return !goog.isString(b) || 1 != b.length ? -1 : a.indexOf(b, c);

  for (; c < a.length; c++) if (c in a && a[c] === b) return c;

  return -1;
};
goog.array.lastIndexOf = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.lastIndexOf) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.lastIndexOf.call(a, b, null == c ? a.length - 1 : c);
} : function (a, b, c) {
  c = null == c ? a.length - 1 : c;
  0 > c && (c = Math.max(0, a.length + c));
  if (goog.isString(a)) return !goog.isString(b) || 1 != b.length ? -1 : a.lastIndexOf(b, c);

  for (; 0 <= c; c--) if (c in a && a[c] === b) return c;

  return -1;
};
goog.array.forEach = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.forEach) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  goog.array.ARRAY_PROTOTYPE_.forEach.call(a, b, c);
} : function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, f = 0; f < d; f++) f in e && b.call(c, e[f], f, a);
};

goog.array.forEachRight = function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, d = d - 1; 0 <= d; --d) d in e && b.call(c, e[d], d, a);
};

goog.array.filter = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.filter) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.filter.call(a, b, c);
} : function (a, b, c) {
  for (var d = a.length, e = [], f = 0, g = goog.isString(a) ? a.split("") : a, h = 0; h < d; h++) if (h in g) {
    var i = g[h];
    b.call(c, i, h, a) && (e[f++] = i);
  }

  return e;
};
goog.array.map = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.map) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.map.call(a, b, c);
} : function (a, b, c) {
  for (var d = a.length, e = Array(d), f = goog.isString(a) ? a.split("") : a, g = 0; g < d; g++) g in f && (e[g] = b.call(c, f[g], g, a));

  return e;
};
goog.array.reduce = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.reduce) ? function (a, b, c, d) {
  goog.asserts.assert(null != a.length);
  d && (b = goog.bind(b, d));
  return goog.array.ARRAY_PROTOTYPE_.reduce.call(a, b, c);
} : function (a, b, c, d) {
  var e = c;
  goog.array.forEach(a, function (c, g) {
    e = b.call(d, e, c, g, a);
  });
  return e;
};
goog.array.reduceRight = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.reduceRight) ? function (a, b, c, d) {
  goog.asserts.assert(null != a.length);
  d && (b = goog.bind(b, d));
  return goog.array.ARRAY_PROTOTYPE_.reduceRight.call(a, b, c);
} : function (a, b, c, d) {
  var e = c;
  goog.array.forEachRight(a, function (c, g) {
    e = b.call(d, e, c, g, a);
  });
  return e;
};
goog.array.some = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.some) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.some.call(a, b, c);
} : function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, f = 0; f < d; f++) if (f in e && b.call(c, e[f], f, a)) return !0;

  return !1;
};
goog.array.every = goog.NATIVE_ARRAY_PROTOTYPES && (goog.array.ASSUME_NATIVE_FUNCTIONS || goog.array.ARRAY_PROTOTYPE_.every) ? function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.every.call(a, b, c);
} : function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, f = 0; f < d; f++) if (f in e && !b.call(c, e[f], f, a)) return !1;

  return !0;
};

goog.array.count = function (a, b, c) {
  var d = 0;
  goog.array.forEach(a, function (a, f, g) {
    b.call(c, a, f, g) && ++d;
  }, c);
  return d;
};

goog.array.find = function (a, b, c) {
  b = goog.array.findIndex(a, b, c);
  return 0 > b ? null : goog.isString(a) ? a.charAt(b) : a[b];
};

goog.array.findIndex = function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, f = 0; f < d; f++) if (f in e && b.call(c, e[f], f, a)) return f;

  return -1;
};

goog.array.findRight = function (a, b, c) {
  b = goog.array.findIndexRight(a, b, c);
  return 0 > b ? null : goog.isString(a) ? a.charAt(b) : a[b];
};

goog.array.findIndexRight = function (a, b, c) {
  for (var d = a.length, e = goog.isString(a) ? a.split("") : a, d = d - 1; 0 <= d; d--) if (d in e && b.call(c, e[d], d, a)) return d;

  return -1;
};

goog.array.contains = function (a, b) {
  return 0 <= goog.array.indexOf(a, b);
};

goog.array.isEmpty = function (a) {
  return 0 == a.length;
};

goog.array.clear = function (a) {
  if (!goog.isArray(a)) for (var b = a.length - 1; 0 <= b; b--) delete a[b];
  a.length = 0;
};

goog.array.insert = function (a, b) {
  goog.array.contains(a, b) || a.push(b);
};

goog.array.insertAt = function (a, b, c) {
  goog.array.splice(a, c, 0, b);
};

goog.array.insertArrayAt = function (a, b, c) {
  goog.partial(goog.array.splice, a, c, 0).apply(null, b);
};

goog.array.insertBefore = function (a, b, c) {
  var d;
  2 == arguments.length || 0 > (d = goog.array.indexOf(a, c)) ? a.push(b) : goog.array.insertAt(a, b, d);
};

goog.array.remove = function (a, b) {
  var c = goog.array.indexOf(a, b),
      d;
  (d = 0 <= c) && goog.array.removeAt(a, c);
  return d;
};

goog.array.removeAt = function (a, b) {
  goog.asserts.assert(null != a.length);
  return 1 == goog.array.ARRAY_PROTOTYPE_.splice.call(a, b, 1).length;
};

goog.array.removeIf = function (a, b, c) {
  b = goog.array.findIndex(a, b, c);
  return 0 <= b ? (goog.array.removeAt(a, b), !0) : !1;
};

goog.array.concat = function (a) {
  return goog.array.ARRAY_PROTOTYPE_.concat.apply(goog.array.ARRAY_PROTOTYPE_, arguments);
};

goog.array.toArray = function (a) {
  var b = a.length;

  if (0 < b) {
    for (var c = Array(b), d = 0; d < b; d++) c[d] = a[d];

    return c;
  }

  return [];
};

goog.array.clone = goog.array.toArray;

goog.array.extend = function (a, b) {
  for (var c = 1; c < arguments.length; c++) {
    var d = arguments[c],
        e;
    if (goog.isArray(d) || (e = goog.isArrayLike(d)) && Object.prototype.hasOwnProperty.call(d, "callee")) a.push.apply(a, d);else if (e) for (var f = a.length, g = d.length, h = 0; h < g; h++) a[f + h] = d[h];else a.push(d);
  }
};

goog.array.splice = function (a, b, c, d) {
  goog.asserts.assert(null != a.length);
  return goog.array.ARRAY_PROTOTYPE_.splice.apply(a, goog.array.slice(arguments, 1));
};

goog.array.slice = function (a, b, c) {
  goog.asserts.assert(null != a.length);
  return 2 >= arguments.length ? goog.array.ARRAY_PROTOTYPE_.slice.call(a, b) : goog.array.ARRAY_PROTOTYPE_.slice.call(a, b, c);
};

goog.array.removeDuplicates = function (a, b, c) {
  for (var b = b || a, c = c || function () {
    return goog.isObject(g) ? "o" + goog.getUid(g) : (typeof g).charAt(0) + g;
  }, d = {}, e = 0, f = 0; f < a.length;) {
    var g = a[f++],
        h = c(g);
    Object.prototype.hasOwnProperty.call(d, h) || (d[h] = !0, b[e++] = g);
  }

  b.length = e;
};

goog.array.binarySearch = function (a, b, c) {
  return goog.array.binarySearch_(a, c || goog.array.defaultCompare, !1, b);
};

goog.array.binarySelect = function (a, b, c) {
  return goog.array.binarySearch_(a, b, !0, void 0, c);
};

goog.array.binarySearch_ = function (a, b, c, d, e) {
  for (var f = 0, g = a.length, h; f < g;) {
    var i = f + g >> 1,
        j;
    j = c ? b.call(e, a[i], i, a) : b(d, a[i]);
    0 < j ? f = i + 1 : (g = i, h = !j);
  }

  return h ? f : ~f;
};

goog.array.sort = function (a, b) {
  a.sort(b || goog.array.defaultCompare);
};

goog.array.stableSort = function (a, b) {
  for (var c = 0; c < a.length; c++) a[c] = {
    index: c,
    value: a[c]
  };

  var d = b || goog.array.defaultCompare;
  goog.array.sort(a, function (a, b) {
    return d(a.value, b.value) || a.index - b.index;
  });

  for (c = 0; c < a.length; c++) a[c] = a[c].value;
};

goog.array.sortObjectsByKey = function (a, b, c) {
  var d = c || goog.array.defaultCompare;
  goog.array.sort(a, function (a, c) {
    return d(a[b], c[b]);
  });
};

goog.array.isSorted = function (a, b, c) {
  for (var b = b || goog.array.defaultCompare, d = 1; d < a.length; d++) {
    var e = b(a[d - 1], a[d]);
    if (0 < e || 0 == e && c) return !1;
  }

  return !0;
};

goog.array.equals = function (a, b, c) {
  if (!goog.isArrayLike(a) || !goog.isArrayLike(b) || a.length != b.length) return !1;

  for (var d = a.length, c = c || goog.array.defaultCompareEquality, e = 0; e < d; e++) if (!c(a[e], b[e])) return !1;

  return !0;
};

goog.array.compare3 = function (a, b, c) {
  for (var c = c || goog.array.defaultCompare, d = Math.min(a.length, b.length), e = 0; e < d; e++) {
    var f = c(a[e], b[e]);
    if (0 != f) return f;
  }

  return goog.array.defaultCompare(a.length, b.length);
};

goog.array.defaultCompare = function (a, b) {
  return a > b ? 1 : a < b ? -1 : 0;
};

goog.array.defaultCompareEquality = function (a, b) {
  return a === b;
};

goog.array.binaryInsert = function (a, b, c) {
  c = goog.array.binarySearch(a, b, c);
  return 0 > c ? (goog.array.insertAt(a, b, -(c + 1)), !0) : !1;
};

goog.array.binaryRemove = function (a, b, c) {
  b = goog.array.binarySearch(a, b, c);
  return 0 <= b ? goog.array.removeAt(a, b) : !1;
};

goog.array.bucket = function (a, b, c) {
  for (var d = {}, e = 0; e < a.length; e++) {
    var f = a[e],
        g = b.call(c, f, e, a);
    goog.isDef(g) && (d[g] || (d[g] = [])).push(f);
  }

  return d;
};

goog.array.toObject = function (a, b, c) {
  var d = {};
  goog.array.forEach(a, function (e, f) {
    d[b.call(c, e, f, a)] = e;
  });
  return d;
};

goog.array.range = function (a, b, c) {
  var d = [],
      e = 0,
      f = a,
      c = c || 1;
  void 0 !== b && (e = a, f = b);
  if (0 > c * (f - e)) return [];
  if (0 < c) for (a = e; a < f; a += c) d.push(a);else for (a = e; a > f; a += c) d.push(a);
  return d;
};

goog.array.repeat = function (a, b) {
  for (var c = [], d = 0; d < b; d++) c[d] = a;

  return c;
};

goog.array.flatten = function (a) {
  for (var b = [], c = 0; c < arguments.length; c++) {
    var d = arguments[c];
    goog.isArray(d) ? b.push.apply(b, goog.array.flatten.apply(null, d)) : b.push(d);
  }

  return b;
};

goog.array.rotate = function (a, b) {
  goog.asserts.assert(null != a.length);
  a.length && (b %= a.length, 0 < b ? goog.array.ARRAY_PROTOTYPE_.unshift.apply(a, a.splice(-b, b)) : 0 > b && goog.array.ARRAY_PROTOTYPE_.push.apply(a, a.splice(0, -b)));
  return a;
};

goog.array.moveItem = function (a, b, c) {
  goog.asserts.assert(0 <= b && b < a.length);
  goog.asserts.assert(0 <= c && c < a.length);
  b = goog.array.ARRAY_PROTOTYPE_.splice.call(a, b, 1);
  goog.array.ARRAY_PROTOTYPE_.splice.call(a, c, 0, b[0]);
};

goog.array.zip = function (a) {
  if (!arguments.length) return [];

  for (var b = [], c = 0;; c++) {
    for (var d = [], e = 0; e < arguments.length; e++) {
      var f = arguments[e];
      if (c >= f.length) return b;
      d.push(f[c]);
    }

    b.push(d);
  }
};

goog.array.shuffle = function (a, b) {
  for (var c = b || Math.random, d = a.length - 1; 0 < d; d--) {
    var e = Math.floor(c() * (d + 1)),
        f = a[d];
    a[d] = a[e];
    a[e] = f;
  }
};

goog.crypt = {};

goog.crypt.stringToByteArray = function (a) {
  for (var b = [], c = 0, d = 0; d < a.length; d++) {
    for (var e = a.charCodeAt(d); 255 < e;) b[c++] = e & 255, e >>= 8;

    b[c++] = e;
  }

  return b;
};

goog.crypt.byteArrayToString = function (a) {
  return String.fromCharCode.apply(null, a);
};

goog.crypt.byteArrayToHex = function (a) {
  return goog.array.map(a, function (a) {
    a = a.toString(16);
    return 1 < a.length ? a : "0" + a;
  }).join("");
};

goog.crypt.hexToByteArray = function (a) {
  goog.asserts.assert(0 == a.length % 2, "Key string length must be multiple of 2");

  for (var b = [], c = 0; c < a.length; c += 2) b.push(parseInt(a.substring(c, c + 2), 16));

  return b;
};

goog.crypt.stringToUtf8ByteArray = function (a) {
  for (var a = a.replace(/\r\n/g, "\n"), b = [], c = 0, d = 0; d < a.length; d++) {
    var e = a.charCodeAt(d);
    128 > e ? b[c++] = e : (2048 > e ? b[c++] = e >> 6 | 192 : (b[c++] = e >> 12 | 224, b[c++] = e >> 6 & 63 | 128), b[c++] = e & 63 | 128);
  }

  return b;
};

goog.crypt.utf8ByteArrayToString = function (a) {
  for (var b = [], c = 0, d = 0; c < a.length;) {
    var e = a[c++];
    if (128 > e) b[d++] = String.fromCharCode(e);else if (191 < e && 224 > e) {
      var f = a[c++];
      b[d++] = String.fromCharCode((e & 31) << 6 | f & 63);
    } else {
      var f = a[c++],
          g = a[c++];
      b[d++] = String.fromCharCode((e & 15) << 12 | (f & 63) << 6 | g & 63);
    }
  }

  return b.join("");
};

goog.crypt.xorByteArray = function (a, b) {
  goog.asserts.assert(a.length == b.length, "XOR array lengths must match");

  for (var c = [], d = 0; d < a.length; d++) c.push(a[d] ^ b[d]);

  return c;
};

goog.userAgent = {};
goog.define("goog.userAgent.ASSUME_IE", !1);
goog.define("goog.userAgent.ASSUME_GECKO", !1);
goog.define("goog.userAgent.ASSUME_WEBKIT", !1);
goog.define("goog.userAgent.ASSUME_MOBILE_WEBKIT", !1);
goog.define("goog.userAgent.ASSUME_OPERA", !1);
goog.define("goog.userAgent.ASSUME_ANY_VERSION", !1);
goog.userAgent.BROWSER_KNOWN_ = goog.userAgent.ASSUME_IE || goog.userAgent.ASSUME_GECKO || goog.userAgent.ASSUME_MOBILE_WEBKIT || goog.userAgent.ASSUME_WEBKIT || goog.userAgent.ASSUME_OPERA;

goog.userAgent.getUserAgentString = function () {
  return goog.global.navigator ? goog.global.navigator.userAgent : null;
};

goog.userAgent.getNavigator = function () {
  return goog.global.navigator;
};

goog.userAgent.init_ = function () {
  goog.userAgent.detectedOpera_ = !1;
  goog.userAgent.detectedIe_ = !1;
  goog.userAgent.detectedWebkit_ = !1;
  goog.userAgent.detectedMobile_ = !1;
  goog.userAgent.detectedGecko_ = !1;
  var a;

  if (!goog.userAgent.BROWSER_KNOWN_ && (a = goog.userAgent.getUserAgentString())) {
    var b = goog.userAgent.getNavigator();
    goog.userAgent.detectedOpera_ = goog.string.startsWith(a, "Opera");
    goog.userAgent.detectedIe_ = !goog.userAgent.detectedOpera_ && (goog.string.contains(a, "MSIE") || goog.string.contains(a, "Trident"));
    goog.userAgent.detectedWebkit_ = !goog.userAgent.detectedOpera_ && goog.string.contains(a, "WebKit");
    goog.userAgent.detectedMobile_ = goog.userAgent.detectedWebkit_ && goog.string.contains(a, "Mobile");
    goog.userAgent.detectedGecko_ = !goog.userAgent.detectedOpera_ && !goog.userAgent.detectedWebkit_ && !goog.userAgent.detectedIe_ && "Gecko" == b.product;
  }
};

goog.userAgent.BROWSER_KNOWN_ || goog.userAgent.init_();
goog.userAgent.OPERA = goog.userAgent.BROWSER_KNOWN_ ? goog.userAgent.ASSUME_OPERA : goog.userAgent.detectedOpera_;
goog.userAgent.IE = goog.userAgent.BROWSER_KNOWN_ ? goog.userAgent.ASSUME_IE : goog.userAgent.detectedIe_;
goog.userAgent.GECKO = goog.userAgent.BROWSER_KNOWN_ ? goog.userAgent.ASSUME_GECKO : goog.userAgent.detectedGecko_;
goog.userAgent.WEBKIT = goog.userAgent.BROWSER_KNOWN_ ? goog.userAgent.ASSUME_WEBKIT || goog.userAgent.ASSUME_MOBILE_WEBKIT : goog.userAgent.detectedWebkit_;
goog.userAgent.MOBILE = goog.userAgent.ASSUME_MOBILE_WEBKIT || goog.userAgent.detectedMobile_;
goog.userAgent.SAFARI = goog.userAgent.WEBKIT;

goog.userAgent.determinePlatform_ = function () {
  var a = goog.userAgent.getNavigator();
  return a && a.platform || "";
};

goog.userAgent.PLATFORM = goog.userAgent.determinePlatform_();
goog.define("goog.userAgent.ASSUME_MAC", !1);
goog.define("goog.userAgent.ASSUME_WINDOWS", !1);
goog.define("goog.userAgent.ASSUME_LINUX", !1);
goog.define("goog.userAgent.ASSUME_X11", !1);
goog.define("goog.userAgent.ASSUME_ANDROID", !1);
goog.define("goog.userAgent.ASSUME_IPHONE", !1);
goog.define("goog.userAgent.ASSUME_IPAD", !1);
goog.userAgent.PLATFORM_KNOWN_ = goog.userAgent.ASSUME_MAC || goog.userAgent.ASSUME_WINDOWS || goog.userAgent.ASSUME_LINUX || goog.userAgent.ASSUME_X11 || goog.userAgent.ASSUME_ANDROID || goog.userAgent.ASSUME_IPHONE || goog.userAgent.ASSUME_IPAD;

goog.userAgent.initPlatform_ = function () {
  goog.userAgent.detectedMac_ = goog.string.contains(goog.userAgent.PLATFORM, "Mac");
  goog.userAgent.detectedWindows_ = goog.string.contains(goog.userAgent.PLATFORM, "Win");
  goog.userAgent.detectedLinux_ = goog.string.contains(goog.userAgent.PLATFORM, "Linux");
  goog.userAgent.detectedX11_ = !!goog.userAgent.getNavigator() && goog.string.contains(goog.userAgent.getNavigator().appVersion || "", "X11");
  var a = goog.userAgent.getUserAgentString();
  goog.userAgent.detectedAndroid_ = !!a && goog.string.contains(a, "Android");
  goog.userAgent.detectedIPhone_ = !!a && goog.string.contains(a, "iPhone");
  goog.userAgent.detectedIPad_ = !!a && goog.string.contains(a, "iPad");
};

goog.userAgent.PLATFORM_KNOWN_ || goog.userAgent.initPlatform_();
goog.userAgent.MAC = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_MAC : goog.userAgent.detectedMac_;
goog.userAgent.WINDOWS = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_WINDOWS : goog.userAgent.detectedWindows_;
goog.userAgent.LINUX = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_LINUX : goog.userAgent.detectedLinux_;
goog.userAgent.X11 = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_X11 : goog.userAgent.detectedX11_;
goog.userAgent.ANDROID = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_ANDROID : goog.userAgent.detectedAndroid_;
goog.userAgent.IPHONE = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_IPHONE : goog.userAgent.detectedIPhone_;
goog.userAgent.IPAD = goog.userAgent.PLATFORM_KNOWN_ ? goog.userAgent.ASSUME_IPAD : goog.userAgent.detectedIPad_;

goog.userAgent.determineVersion_ = function () {
  var a = "",
      b;
  goog.userAgent.OPERA && goog.global.opera ? (a = goog.global.opera.version, a = "function" == typeof a ? a() : a) : (goog.userAgent.GECKO ? b = /rv\:([^\);]+)(\)|;)/ : goog.userAgent.IE ? b = /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/ : goog.userAgent.WEBKIT && (b = /WebKit\/(\S+)/), b && (a = (a = b.exec(goog.userAgent.getUserAgentString())) ? a[1] : ""));
  return goog.userAgent.IE && (b = goog.userAgent.getDocumentMode_(), b > parseFloat(a)) ? String(b) : a;
};

goog.userAgent.getDocumentMode_ = function () {
  var a = goog.global.document;
  return a ? a.documentMode : void 0;
};

goog.userAgent.VERSION = goog.userAgent.determineVersion_();

goog.userAgent.compare = function (a, b) {
  return goog.string.compareVersions(a, b);
};

goog.userAgent.isVersionOrHigherCache_ = {};

goog.userAgent.isVersionOrHigher = function (a) {
  return goog.userAgent.ASSUME_ANY_VERSION || goog.userAgent.isVersionOrHigherCache_[a] || (goog.userAgent.isVersionOrHigherCache_[a] = 0 <= goog.string.compareVersions(goog.userAgent.VERSION, a));
};

goog.userAgent.isVersion = goog.userAgent.isVersionOrHigher;

goog.userAgent.isDocumentModeOrHigher = function (a) {
  return goog.userAgent.IE && goog.userAgent.DOCUMENT_MODE >= a;
};

goog.userAgent.isDocumentMode = goog.userAgent.isDocumentModeOrHigher;

goog.userAgent.DOCUMENT_MODE = function () {
  var a = goog.global.document;
  return !a || !goog.userAgent.IE ? void 0 : goog.userAgent.getDocumentMode_() || ("CSS1Compat" == a.compatMode ? parseInt(goog.userAgent.VERSION, 10) : 5);
}();

goog.crypt.base64 = {};
goog.crypt.base64.byteToCharMap_ = null;
goog.crypt.base64.charToByteMap_ = null;
goog.crypt.base64.byteToCharMapWebSafe_ = null;
goog.crypt.base64.charToByteMapWebSafe_ = null;
goog.crypt.base64.ENCODED_VALS_BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
goog.crypt.base64.ENCODED_VALS = goog.crypt.base64.ENCODED_VALS_BASE + "+/=";
goog.crypt.base64.ENCODED_VALS_WEBSAFE = goog.crypt.base64.ENCODED_VALS_BASE + "-_.";
goog.crypt.base64.HAS_NATIVE_SUPPORT = goog.userAgent.GECKO || goog.userAgent.WEBKIT || goog.userAgent.OPERA || "function" == typeof goog.global.atob;

goog.crypt.base64.encodeByteArray = function (a, b) {
  if (!goog.isArrayLike(a)) throw Error("encodeByteArray takes an array as a parameter");
  goog.crypt.base64.init_();

  for (var c = b ? goog.crypt.base64.byteToCharMapWebSafe_ : goog.crypt.base64.byteToCharMap_, d = [], e = 0; e < a.length; e += 3) {
    var f = a[e],
        g = e + 1 < a.length,
        h = g ? a[e + 1] : 0,
        i = e + 2 < a.length,
        j = i ? a[e + 2] : 0,
        k = f >> 2,
        f = (f & 3) << 4 | h >> 4,
        h = (h & 15) << 2 | j >> 6,
        j = j & 63;
    i || (j = 64, g || (h = 64));
    d.push(c[k], c[f], c[h], c[j]);
  }

  return d.join("");
};

goog.crypt.base64.encodeString = function (a, b) {
  return goog.crypt.base64.HAS_NATIVE_SUPPORT && !b ? goog.global.btoa(a) : goog.crypt.base64.encodeByteArray(goog.crypt.stringToByteArray(a), b);
};

goog.crypt.base64.decodeString = function (a, b) {
  return goog.crypt.base64.HAS_NATIVE_SUPPORT && !b ? goog.global.atob(a) : goog.crypt.byteArrayToString(goog.crypt.base64.decodeStringToByteArray(a, b));
};

goog.crypt.base64.decodeStringToByteArray = function (a, b) {
  goog.crypt.base64.init_();

  for (var c = b ? goog.crypt.base64.charToByteMapWebSafe_ : goog.crypt.base64.charToByteMap_, d = [], e = 0; e < a.length;) {
    var f = c[a.charAt(e++)],
        g = e < a.length ? c[a.charAt(e)] : 0;
    ++e;
    var h = e < a.length ? c[a.charAt(e)] : 0;
    ++e;
    var i = e < a.length ? c[a.charAt(e)] : 0;
    ++e;
    if (null == f || null == g || null == h || null == i) throw Error();
    d.push(f << 2 | g >> 4);
    64 != h && (d.push(g << 4 & 240 | h >> 2), 64 != i && d.push(h << 6 & 192 | i));
  }

  return d;
};

goog.crypt.base64.init_ = function () {
  if (!goog.crypt.base64.byteToCharMap_) {
    goog.crypt.base64.byteToCharMap_ = {};
    goog.crypt.base64.charToByteMap_ = {};
    goog.crypt.base64.byteToCharMapWebSafe_ = {};
    goog.crypt.base64.charToByteMapWebSafe_ = {};

    for (var a = 0; a < goog.crypt.base64.ENCODED_VALS.length; a++) goog.crypt.base64.byteToCharMap_[a] = goog.crypt.base64.ENCODED_VALS.charAt(a), goog.crypt.base64.charToByteMap_[goog.crypt.base64.byteToCharMap_[a]] = a, goog.crypt.base64.byteToCharMapWebSafe_[a] = goog.crypt.base64.ENCODED_VALS_WEBSAFE.charAt(a), goog.crypt.base64.charToByteMapWebSafe_[goog.crypt.base64.byteToCharMapWebSafe_[a]] = a;
  }
};

var TOKEN_SEP = ".",
    TOKEN_VERSION = 0,
    FirebaseTokenGenerator = function (a) {
  fb.tokengenerator.validation.validateArgCount("new FirebaseTokenGenerator", 1, 1, arguments.length);
  fb.tokengenerator.validation.validateSecret("new FirebaseTokenGenerator", 1, a);
  (this || _global).mSecret = a;
};

goog.exportSymbol("FirebaseTokenGenerator", FirebaseTokenGenerator);

FirebaseTokenGenerator.prototype.createToken = function (a, b) {
  fb.tokengenerator.validation.validateArgCount("FirebaseTokenGenerator.createToken", 1, 2, arguments.length);
  fb.tokengenerator.validation.validateCredentialOptions("FirebaseTokenGenerator.createToken", 2, b);
  b = b || {};
  fb.tokengenerator.validation.validateCredentialData("FirebaseTokenGenerator.createToken", 1, a, !0 === b.admin);
  if (FirebaseTokenGenerator.isEmptyObject_(a) && FirebaseTokenGenerator.isUselessOptionsObject_(b)) throw Error("FirebaseTokenGenerator.createToken: data is empty and no options are set.  This token will have no effect on Firebase.");
  var c = this.createOptionsClaims("FirebaseTokenGenerator.createToken", b);
  c.v = TOKEN_VERSION;
  c.d = a;
  c.iat || (c.iat = Math.floor(new Date().getTime() / 1000));
  return this.createToken_(c);
};

goog.exportProperty(FirebaseTokenGenerator.prototype, "createToken", FirebaseTokenGenerator.prototype.createToken);

FirebaseTokenGenerator.prototype.createOptionsClaims = function (a, b) {
  var c = {},
      d;

  for (d in b) switch (d) {
    case "expires":
    case "notBefore":
      var e = "notBefore" === d ? "nbf" : "exp";
      b[d] instanceof Date ? c[e] = Math.round(b[d].getTime() / 1000) : (fb.tokengenerator.validation.validateOption(a, d, b[d], "number", "a number"), c[e] = b[d]);
      break;

    case "admin":
      fb.tokengenerator.validation.validateOption(a, d, b[d], "boolean", "a boolean");
      c.admin = b[d];
      break;

    case "debug":
      fb.tokengenerator.validation.validateOption(a, d, b[d], "boolean", "a boolean");
      c.debug = b[d];
      break;

    case "simulate":
      fb.tokengenerator.validation.validateOption(a, d, b[d], "boolean", "a boolean");
      c.simulate = b[d];
      break;

    case "iat":
      fb.tokengenerator.validation.validateOption(a, d, b[d], "number", "a number");
      c.iat = b[d];
      break;

    default:
      throw Error(a + ": unrecognized \"" + d + "\" option");
  }

  return c;
};

FirebaseTokenGenerator.prototype.createToken_ = function (a) {
  var b = this.noPadWebsafeBase64Encode_(fb.tokengenerator.json.stringify({
    typ: "JWT",
    alg: "HS256"
  })),
      a = this.noPadWebsafeBase64Encode_(fb.tokengenerator.json.stringify(a)),
      c = CryptoJS.HmacSHA256(b + TOKEN_SEP + a, (this || _global).mSecret).toString(),
      c = this.hexToBytes_(c),
      c = goog.crypt.base64.encodeByteArray(c, !0),
      c = this.removeBase64Pad_(c),
      b = b + TOKEN_SEP + a + TOKEN_SEP + c;
  fb.tokengenerator.validation.validateGeneratedToken(b);
  return b;
};

FirebaseTokenGenerator.prototype.noPadWebsafeBase64Encode_ = function (a) {
  a = fb.tokengenerator.utf8.stringToByteArray(a);
  a = goog.crypt.base64.encodeByteArray(a, !0);
  return this.removeBase64Pad_(a);
};

FirebaseTokenGenerator.prototype.removeBase64Pad_ = function (a) {
  var b = a.indexOf(".");
  return 0 <= b ? a.substring(0, b) : a;
};

FirebaseTokenGenerator.prototype.hexToBytes_ = function (a) {
  for (var b = [], c = 0; c < a.length; c += 2) b.push(parseInt(a.substr(c, 2), 16));

  return b;
};

FirebaseTokenGenerator.isEmptyObject_ = function (a) {
  if ("object" !== typeof a) return !1;
  if (null === a) return !0;

  for (var b in a) if (Object.prototype.hasOwnProperty.call(a, b)) return !1;

  return !0;
};

FirebaseTokenGenerator.isUselessOptionsObject_ = function (a) {
  var b;

  if (!(b = FirebaseTokenGenerator.isEmptyObject_(a))) {
    a: {
      b = ["admin", "debug", "simulate"];

      for (var c in b) if (Object.prototype.hasOwnProperty.call(a, b[c])) {
        a = !0;
        break a;
      }

      a = !1;
    }

    b = !a;
  }

  return b;
};

NODE_CLIENT && (exports = FirebaseTokenGenerator);
export default exports;